.main {
  background-color: #ffffff;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  padding: 0px;
  max-width: 1440px;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* .main__content {
  padding: 0px;
  margin: 0;
  display: flex;
  flex-direction: column;
} */

.main__content-top {
  background-image: url('../../images/georgia-de-lotz.png');
  background-repeat: no-repeat;
  height: 576px;
  margin-top: 0px;
}

.main__title {
  font-family: "Roboto-Slab", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  color: #ffffff;
  width: 608px;
  padding: 160px 416px 0px;
  margin: 0;
}

.main__subtitle {
  font-family: "Roboto", "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  width: 608px;
  padding-top: 32px;
  padding-left: 416px;
}

.main__searchbox {
  height: 64px;
  width: 608px;
  padding-top: 88px;
  padding-left: 416px;
  border-radius: 100px;
}
