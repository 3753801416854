.searchResults {
  display: flex;
  flex-direction: column;
  background-color: #f5f6f7;
  /* height: 974px; */
  padding: 0;
  margin: 0;
  max-width: 1440px;
}

.searchResults__content {
  margin: 80px auto 64px;
  padding: 0;
}

.searchResults__content-title {
  max-width: 712px;
  margin: 0 0 64px 0;
  font-family: 'Roboto-Slab', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1a1b22;
}

.searchResults__content-newscards {
  padding: 0;
  margin: 0;
}

.searchResults__content-showbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 64px calc((100% - 288px)/2) 80px;
  width: 288px;
  height: 64px;
  border-radius: 80px;
  border: none;
  background-color: #ffffff;
  color: #1a1b22;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.searchResults__content-showbtn:hover {
  cursor: pointer;
  background-color: #e8e8e8;
}

/*tablet size*/
@media screen and (max-width: 768px) {
  .searchResults__content {
    margin: 40px auto 32px;
    /* height: 654px; */
  }

  .searchResults__content-title {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 32px;
  }

  .searchResults__content-showbtn {
    width: 240px;
    margin: 32px auto 8px 232px;
  }
}

@media screen and (max-width: 767px) {
  .searchResults__content-showbtn {
    width: 40%;
    margin: 30px auto 12px;
  }
}


@media screen and (max-width: 320px) {
  .searchResults {
    width: 100%;
  }
  .searchResults__content {
    margin: 24px 16px 58px;
    padding: 0;
  }
  .searchResults__content-showbtn {
    width: 288px;
    height: 56px;
    margin: 21px auto 24px;
  }
  .searchResults__content-title {
    margin-top: 8px;
  }
}
