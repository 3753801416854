.nav {
  width: 100%;
  margin: 0 auto;
  padding-top: 14px;
  padding-bottom: 12px;
  color: white;
}
/* Logged Of */
.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height:80px;
  margin: 0;
  padding: 0 104px;
  border-bottom: 1px solid #d1d2d6;
}
.navigation-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height:80px;
  margin: 0;
  padding: 0 104px;
  border-bottom: 1px solid #c4c4c4;
}

.navigation__logo {
  padding-top: 30px;
  padding-bottom: 26px;
}

.navigation__menu {
display: flex;
flex-direction: row;
align-content: center;
margin: 0;
padding: 0;
list-style: none;
}

.navigation__menu-list {
  display: flex;
  margin: 0;
  padding: 0;
}

.navigation__menu-item {
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 0 38px;
}

.navigation__menu-item-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 176px;
  height: 48px;
  border: 1px solid #ffffff;
  border-radius: 100px;
  text-decoration: none;
  background: none;
  margin: 19px auto 13px 34px;
}
/*
.navigation__menu-item:visited {
  width: 68px;
  color: #ffffff;
  border-bottom: 3px solid #ffffff ;
} */

.navigation__menu-item:active {
  border-bottom: 3px solid #ffffff ;
}



.navigation__menu-item-button:visited {
  border: 1px solid #ffffff;
  text-decoration: inherit;
}

.navigation__menu-item-button:active {
  border: 1px solid #ffffff;
  text-decoration: inherit;
}

.navigation__menu-item-link {
  text-decoration: none;
}

.navigation__menu-item-link:active {
  border-bottom:  none;
  }

.navigation__menu-item-link:visited {
  border-bottom:  grey;
}

/*logged in */
.navigation__menu-item-dark {
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
  text-decoration: none;
  margin-top: 30px;
  /* margin-right: 44px; */
  padding: 0 38px;
  cursor: pointer;
  background: none;
}

.active {
  border-bottom: 3px solid;
}



.navigation__menu-item-button-dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 54px 12px 19px;
  /* width: 176px; */
  height: 40px;
  border: 1px solid #1a1b22;
  border-radius: 100px;
  text-decoration: none;
  margin: 19px 0 13px 26px;
  background-image: url('../../images/exitIcon.jpg');
  background-repeat: no-repeat;
  background-position-x:calc(100% - 19px);
  background-position-y: center;
}
.navigation__menu-item-button-dark-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 19px 0 13px 26px;
  padding: 12px 54px 12px 19px;
  /* width: 176px; */
  height: 40px;
  border: 1px solid #ffffff;
  border-radius: 100px;
  text-decoration: none;
  background-color: transparent;
  background-image: url('../../images/Union.svg');
  background-repeat: no-repeat;
  background-position-x:calc(100% - 19px);
  background-position-y: center;
}

.navigation__menu-item-button-dark:visited {
  border: 1px solid #1a1b22;
}
.navigation__menu-item-button:active {
  border: 1px solid #1a1b22;
}

.navigation__menu-item-button_mobile {
  display: none;
}

.navigation__menu-item-button-dark-mobile {
  display: none;
}

/* tablet - size */
@media screen and (max-width: 953px) and (min-width: 767px){

  .navigation {
    padding: 0 40px;
    height: 66px;
    border-bottom: 1px solid #d1d2d6;
  }

  .navigation-home {
    padding: 0 40px;
    height: 66px;
  }

  .navigation__logo {
    width: 200px;
    /* margin-right: 132px; */
  }

  .navigation__menu-item {
    margin: 0px 8px ;
    padding: 22px 19px;
    font-size: 16px;
    line-height: 24px;
    max-width: 150px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    /* text-align: left; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .navigation__menu-item-button {
    width: 152px;
    height: 40px;
    margin: 14px auto 12px 8px;
    padding: 0;
    border: 1px solid #ffffff;
    border-radius: 100px;
    background: none;
  }

  .navigation__menu-item-button:visited {
    border: 1px solid #ffffff;
    /* text-decoration: inherit; */
  }
  .navigation__menu-item-button:active {
    border: 1px solid #ffffff;
  }

  .navigation__menu-item-dark {
    margin: 0px 8px ;
    padding: 22px 19px;
    font-size: 16px;
    line-height: 24px;

  }
  .navigation__menu-item-button-dark {
    margin: 14px 0 auto 8px;
    /* padding-left: 16px; */
    padding: 0px 50px 0px 15px;
    background-position-x:calc(90%);
  }
  .navigation__menu-item-button-dark-home {
    margin: 14px 0 12px 8px;
    padding: 8px 54px 12px 15px;
    /* width: 176px; */
    height: 40px;
    border: 1px solid #ffffff;
    border-radius: 100px;
    background-position-x:calc(90%);
    background-position-y: calc(40%);
  }
}

/* @media screen and (max-width: 595px) and (min-width: 325px) {
  .navigation {
    border-bottom-width: 100%;
  }
  .navigation__logo-image {
    width: 60px;
    height: 20px;
  }
  .navigation__menu-item {
    font-size: 10px;
  }
    .navigation__menu-item-button {
    width: 60px;
    height: 30px;
    margin: 14px auto 12px 8px;
  }
} */


@media screen and (max-width: 767px) and (min-width: 318px) {
  .navigation {
    height: 56px;
    padding: 0 16px;
  }
  .navigation__logo {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 117px;
  }
  .navigation__logo-image {
    width: 117px;
    height: 20px;
  }
  .navigation__menu-item {
    display: none;
  }

  .navigation__menu-item-dark {
    display: none;
  }

  .navigation__menu-item-button_mobile {
    display: block;
    background-image: url('../../images/menu.svg');
    margin: 16px 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0;
  }

  .navigation__menu-item-button_mobile:hover {
    cursor: pointer;
  }

  .navigation__menu-item-button-dark-mobile {
    display: block;
    background-image: url('../../images/menu-dark.svg');
    margin: 16px 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0;
  }
  .navigation__menu-item-button-dark-mobile:hover {
    cursor: pointer;
  }
}

