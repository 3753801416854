.about {
  display: flex;
  flex-direction: row;
  padding: 0;
  /* margin-bottom: 80px; */
  height: 624px;
}

.about__image {
  /* background-image: url(../../images/author-image.jpeg); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 464px;
  height: 464px;
  border-radius: 1000px;
  margin: 80px 0 80px 104px;
  /* margin-top: 80px;
  margin-left: 104px; */
}

.about__content {
 display: flex;
 flex-direction: column;
 width: 600px;
 height: 190px;
 margin-top: 131px;
 margin-left: 56px;
}

.about__title {
  font-family: "Roboto-Slab", "Arial", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1A1B22;
  margin-bottom: 24px;
}

.about__description {
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {

  .about__image {
    max-width: 232px;
    height: 232px;
    border-radius: 50px;
    margin: 61px auto 61px 40px;
    border-radius: 120px;
  }
  .about__content {
    width: 424px;
    margin-top: 40px;
    margin-left: 32px;
  }
  .about__title {
    font-size: 30px;
    line-height: 40px;
    margin: 0;
  }
}

@media screen and (max-width:766px) and (min-width: 318px) {
  .about {
    display: flex;
    flex-direction: column;
    height: 1000px;
    align-items: center;
  }
  .about__image {
    width: 272px;
    max-height: 272px;
    margin: 32px 24px 24px;
  }
  .about__content {
    max-width: 288px;
    margin: 0;
    /* padding: 0 16px;
    text-align: left; */
  }
  .about__title {
    max-width: 288px;
    padding: 0;
    margin: 0 16px;
  }
  .about__description{
    display: flex;
    flex-direction: column;
    margin: auto 16px;
    font-family: 'Roboto', 'Arial', 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    margin-top: 24px;
  }
}
