.footer {
  height: 80px;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.footer__copyright {
  margin: 32px auto 26px 104px;
  width: 433px;
  color: #b6bcbf;
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.footer__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 347px;
  margin-right: 106px;
}

.footer__menu-item {
  padding: 0px;
  margin-right: 40px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color:#1a1b22;
  text-decoration: none;
}

.footer__menu-icon {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .footer {
    max-width: 768px;
    height: 64px;
  }
  .footer__copyright {
    margin: 24px auto 18px 40px;
  }
  .footer__menu {
    margin-right: 0px;
    align-items: unset;
    margin-top:21px;
    margin-bottom: 19px;
  }
}

@media screen and (max-width: 400px) and (min-width: 318px) {
  .footer {
    display: flex;
    flex-direction: column-reverse;
    max-width: 320px;
    height: 176px;
    margin-top: 128px;
  }
  .footer__menu {
    display: grid;
    grid-template-columns: repeat(2, 144px);
    /* margin: 0;
    padding: 0; */
    max-width: 288px;

  }
  .footer__menu-item {
    grid-column: 1 / 2;
    row-gap: 10px;
    padding-left:16px;
  }
  .footer__menu-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-row: 1;
    grid-column: 2;
    padding-right: 13px;
  }
  .footer__copyright {
    font-size: 16px;
    line-height: 22px;
    max-width: 288px;
    margin: 15px auto 22px;
  }
}
