.app {
  max-width: 1440px;
  margin: 0;
  padding: 0;
  position:relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}


/* tablet*/
@media screen and (max-width: 768px ) {
  .app {
    max-width: 768px;
  }
}

@media screen and (max-width: 320px ) {
  .app {
    max-width: 320px;
  }
}
