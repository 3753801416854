.popup-mobile {
  display: none;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 50%);
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
}

.popup-mobile_open {
  display:block;
  opacity: 1;
}

.popup-mobile__container {
  width: 320px;
  height: auto;
  background-color: #1a1b22;
}

.popup-mobile__close {
  width: 24px;
  height: 24px;
  margin: 16px 11px 16px auto;
  position: absolute;
  /* top: 5px; */
  right: 5px;
  /*left: calc(50% + 215px);*/
  background-color: transparent;
  background-image: url(../../images/mobile-close.svg);
  background-repeat: no-repeat;
  border: 0;
  transition: opacity 0.8s linear 0.15s;
}

.popup-mobile__btn {
  width: 288px;
  height: 56px;
  border-radius: 40px;
  border: 1px solid white;
  background-color: #1a1b22;
  color: white;
  font-family: 'Roboto', "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding: 16px auto;
  margin: 6px auto 24px 16px;
}
.popup-mobile__btn:hover {
  cursor: pointer;
}

.popup-mobile-logo {
  margin: 16px auto 16px 16px;
  width: 117px;
  height: 20px;
}

.popup-mobile-text {
  font-family: 'Roboto', 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 56px;
  color: #ffffff;
  margin: 16px;
  width: 288px;
}

.popup-mobile-text:visited {
  text-decoration: none;
  color: #ffffff;
}
.popup-mobile-text:active {
  text-decoration: none;
  color: #ffffff;
}
