.preloader {
  max-width: 1440px;
  height: 282px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F6F7;
  ;
}

.preloader__icon {
  margin: 80px auto 24px;
  background-color: transparent;
  width: 64px;
  height: 64px;
  border: 2px solid  gray;
  border-top-color: #1A1B22;
  border-left-color: #D1D2D600;
  /* border-bottom-color: #7f869c; */
  border-radius: 50%;
  transform: rotate(30deg);
  animation: spin 2.5s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg);}
  50% { transform: rotate(180deg);}
  75% { transform: rotate(270deg);}
  100% { transform: rotate(360deg);}

}

.preloader__title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color:  #b6bcbf;
}
