.popup {
  display:flex;
  opacity: 0;
  visibility: hidden;
  /* transition: visibility 0.6s, opacity 0.6s linear; */
  background-color: rgba(0, 0, 0, 50%);
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items:center;
}

.popup_open {
  visibility: visible;
  opacity: 1;
  /* transition: visibility 0.1s, opacity 0.6s linear; */
  margin: 0;
}

.popup__square {
  width: 430px;
  background-color: white;
  color: #000000;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-items: center;
}

.popup__form {
  width: 100%;
  margin: 0px;
  padding: 34px 36px 28px;
  display: flex;
  flex-direction: column;
}

.popup__close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -40px;
  right: -40px;
  /*left: calc(50% + 215px);*/
  background-color: transparent;
  background-image: url(../../images/Close-Icon.svg);
  background-repeat: no-repeat;
  border: 0;
  transition: opacity 0.8s linear 0.15s;
}

.popup__close:hover {
  cursor: pointer;
  opacity: 0.6;
  transform: rotate(-45deg);
}

.popup__title {
  width: 330px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 22px;
  font-family: 'Inter', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.20;
  color: black;
}

.popup__title_confirmDelete {
  text-align: center;
}

.popup__square_success {
  display: flex;
  flex-direction: column;
  padding: 43px 79px 51px 36px;
}

.popup__success-title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 900;
  font-size: 24px;
  line-height: 28.13px;
  color: #000000;
  width: 315px;
  margin-bottom: 14px;
}

.popup__success-link {
  text-decoration: none;
  color: #2f71e5;
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.78px;
}

.popup__success-link:visited {
  text-decoration: none;
  color: #2f71e5;
}

.popup__success-link:active {
  text-decoration: none;
  color: #2f71e5;
}

.popup__alt {
  color: #000000;
  font-family: 'Inter', "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  text-align: center;
  margin: 16px 0 0;
}

.popup__alt:link {
  text-decoration: none;
  color: #2f71e5;
}

.popup__alt:visited {
  text-decoration: none;
  color: #2f71e5;
}

.popup__save {
  max-width: 360px;
  height: 64px;
  border-radius: 40px;
  border: 0px;
  background-color: #2f71e5;
  color: white;
  font-family: 'Roboto', "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding: 20px auto;
  margin: 8px 0px 0px;
}

.popup__save:hover:not(:disabled) {
  cursor: pointer;
  opacity: 0.8;
}

.popup__save_disabled {
  /* max-width: 358px;
  width: 100%;
  border-radius: 2px;
  border: 0px; */
  padding: 20px auto;
  background-color: #E6e8eb;
  color: #b6bcbf;
  border-color: #000000;
  /* opacity: 0.2; */
}

.popup__formfield {
  display: flex;
  flex-direction: column;
  position: relative;
}

.popup__input {
  font-family: 'Inter', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin: 0px 0px 30px;
  width: 358px;
  padding: 0px 0px 10px;
}

/* .popup__input:last-child {
  margin-bottom: 39px;
} */

.popup__input-mail-registered {
  display: block;
  padding: 0;
  margin: 0 0 8px ;
  text-align: center;
  color: transparent;
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
}

.popup__input-mail-registered_open{
  color: #ff0000;
}

.popup__input-error {
  display: none;
}

.popup__input-error_open {
  display: block;
  font-family: 'Inter', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  opacity: 1;
  text-decoration: none;
  position: absolute;
  top: 70%;
 }

 .popup__formfield {
  display: flex;
  flex-direction: column;
  position: relative;
}

.popup__formfield__labeltype {
  color: #2f71e5;
  font-family: 'Inter', 'Arial', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 100%;
  margin: 0 0 9px;

}
.popup__error_visible {
  border-bottom: 1px solid #FF0000;
  /*border-top: 0;
    border-left: 0;
    border-right: 0;*/
}


@media screen and (max-width: 768px) {
  .popup__input {
    margin-bottom: 31px;
  }

}

@media screen and (max-width: 320px) {
  .popup__square {
    width: 320px;
    margin: 0;
  }
  .popup__form {
    width: 288px;
    padding: 16px;
    border-radius: 25px;
  }
  .popup__close{
    top: -50px;
    right: 0px;
  }
  .popup__square_success {
    display: flex;
    flex-direction: column;
    width:90%;
    padding: 8px 8px 24px;
  }
  .popup__success-title {
    font-size: 22px;
    line-height: 26px;
  }

  .popup__success-link {
    font-size: 16px;
    line-height: 20px;
  }
}
