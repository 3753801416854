/* .search-form {
  background-color: #ffffff;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  padding: 0px;
  width: 1440px;
  max-height: 1280px;
  position: relative;
} */

.search-form__content {
  max-width: 1440px;
  padding: 0px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.search-form__content-top {
  margin-top: 0px;
}

.search-form__content-title {
  font-family: 'Roboto-Slab', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  color: #ffffff;
  width: 608px;
  margin: 80px auto 0;
  padding: 0;
}

.search-form__content-subtitle {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  width: 608px;
  margin: 32px auto 0;
  /* padding-top: 32px;
  padding-left: 416px; */
  /* margin: 0; */
}

.search-form__content-form {
  margin: 0;
  padding: 0;
  border: 0;
}
.search-form__content-form-field {
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 608px;
  margin-top: 88px;
  margin-left: calc((100% - 608px)/2);
  background-color: #ffffff;
  border-radius: 100px;
  border: 1px solid #D1D2D6;
}

.search-form__content-form-input {
  width: 376px;
  height: 22px;
  margin: 21px 24px;
  padding: 0;
  border: 0;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #B6BCBF;
}

.search-form__content-form-input:hover {
  color: #1a1b22
}

.search-form__content-form-input:active {
  color: #1a1b22
}

.search-form__content-form_submit {
  border: 0;
  margin-left: 16px;
  width: 168px;
  background-color: #2F71E5;
  color: #ffffff;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.search-form__content-form_submit:hover {
  cursor: pointer;
  background-color: #347eff;
}

.search-form__content-form_submit:active {
  background-color: #2a65cc ;
}

/*table size*/
@media screen and (max-width: 768px) {
  .search-form__content {
    max-width: 768px;
    height: 344px;
  }

  .search-form__content-top {
    height: 332px;
    margin: 0;
    padding: 0;
  }

  .search-form__content-title {
    margin: 38px 220px 0 163px;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    max-width: 385px;
  }

  .search-form__content-subtitle {
    padding-top: 16px;
    padding-left: auto;
    width: 452px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .search-form__content-form-field{
    height: 56px;
    width: 448px;
    margin: 48px auto;
  }

  .search-form__content-form-input {
    width: 228px;
    height: 22px;
    margin: 17px 24px;
    font-size: 16px;
    line-height: 22px;
  }

  .search-form__content-form_submit {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 16px 52px;
  }
}

@media screen and (max-width: 767px) and (min-width: 321px) {
  .search-form__content-title {
    margin: 30px auto 0;
    width: 80%;
    font-size: 24px;
  }
  .search-form__content-form-field {
    width: 70%;
    margin-top: 15px;
  }
  .search-form__content-subtitle {
    margin-left: auto;
    margin-bottom: 0;
    width: 70%;
    font-size:18px;
  }
  .search-form__content-form_submit{
    margin:auto;
    width: 60%;
  }
  .search-form__content-form-input {
    width: 60%;
  }
}


@media screen and (max-width: 320px ) {
  .search-form__content {
    height: 480px;
  }
  .search-form__content-top {
    height: 480px;
  }

  .search-form__content-title {
    width: 285px;
    margin: 30px 16px 0px 16px;
    padding: 0;
  }

  .search-form__content-subtitle {
    padding: 16px 16px 0 16px;
    width: 288px;
    margin: 0;
  }

  .search-form__content-form {
    width: 288px;
    margin: 122px 16px 0;
    padding: 0;
  }
  .search-form__content-form-field {
    flex-direction: column;
    width: 288px;
    margin: 0px;
  }
  .search-form__content-form-input {
    width: 253px;
    margin: 0;
    padding: 17px 16px;
    border-radius: 100px;
  }
  .search-form__content-form_submit {
    margin: 16px 0;
    width: 288px;
    padding:16px 116px;
  }
}
