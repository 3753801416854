.header {
  margin: 0;
  padding: 0;
}

/* .header__content {
 padding: 0;
 margin: 0;
 max-width: 1440px;
 height: 576px;
 } */

.header__nologin {
  background-image: url('../../images/georgia-de-lotz.png');
  background-repeat: no-repeat;
  /* background-size: contain; */
  max-width: 1440px;
  height: 576px;
  padding: 0;
  margin: 0;
}

.header__logged-in {
  background-color: #ffffff;
  height: 80px;
}

/* tablet size*/
@media screen and (max-width: 768px) {
  .header {
    max-width: 768px;
  }

  .header__nologin {
    background-image: url('../../images/georgia-de-lotz--tabletsize.png');
    height: 400px;
  }

  .header__logged-in {
    height: 66px;
  }
}

@media screen and (max-width: 320px) {
  .header {
    max-width: 320px;
  }
  .header__nologin {
    background-image: url('../../images/georgia-de-lotz--320p.png');
    background-size: cover;
    height: 536px;
  }

  .header__logged-in {
    height: 56px;
  }

}
