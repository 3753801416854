.savedarticles__container {
  border-top: 1px solid #d1d2d6;
  max-width: 1440px;
  height: 294px;
}

.savedarticles {
  padding: 0;
  margin: 40px auto 56px 104px;
  max-width: 712px;
}

.savedarticles__tagtext {
  font-family: 'Roboto', 'Arial', sans-serif ;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: rgba(26, 27, 34, 0.5)
}

.savedarticles__title {
  width: 530px;
  font-family: 'Roboto-Slab', 'Arial', sans-serif ;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
  line-height: 46px;
  color: #1a1b22;
}

.savedarticles__keywords {
  font-family: 'Roboto', 'Arial', sans-serif ;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
}

.savedarticles__keywords_bold {
  font-family: 'Roboto', 'Arial', sans-serif ;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
}

@media screen and (max-width: 768px) {
  .savedarticles__container {
    display: flex;
    border: 0;
    height: 204px;
  }
  .savedarticles {
    margin: 24px 0 8px 40px;
    padding: 0;
  }
  .savedarticles__tagtext {
    font-size: 14px;
    line-height: 24px;
  }
  .savedarticles__title {
    font-size: 30px;
    line-height: 34px;
    width: 336px;
    margin: 16px 0;
  }
  .savedarticles__keywords {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 405px) and (min-width: 319px) {
  .savedarticles__container {
    height: 240px;
  }
  .savedarticles {
    margin: 8px 16px 32px;
    padding: 0;
  }

  .savedarticles__tagtext {
    font-size: 12px;
  }
  .savedarticles__title {
    font-size: 30px;
    width: 288px;
  }
  .savedarticles__keywords {
    width: 288px;
    margin-top: 50px;
    margin-bottom: 0;

  }

}
