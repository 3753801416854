.searchResults__newscard {
  display: flex;
  justify-content: center;
  /* max-width: 1440px; */
  /* margin-right: 16px; */
}

.searchResults__newscard:last-of-type {
  margin-right: 0;
}

.searchResults__newscard-item {
  margin: 0;
  padding: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  height: 576px;
  position: relative;
  border-radius: 20px;
}

.searchResults__newscard-item-image {
  max-width: 400px;
  height: 272px;
  border-radius: 20px 20px 0 0;
  background-color: transparent ;
}

.searchResults__newscard-item-box {
  border-radius: 8px;
  border: 0;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.searchResults__newscard-item-box:hover {
  cursor: pointer;
}

.searchResults__newscard-item-tooltip {
  /* display: none; */
  border-radius: 10px;
  border: 0;
  background-color: #ffffff;
  width: 182px;
  height: 40px;
  position: absolute;
  top: 24px;
  right: 69px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.06px;
  color: #000000;
}

.searchResults__newscard-item-bookmark {
  background-image: url("../../images/bookmark-blank.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  padding: 0;
}

.searchResults__newscard-item-bookmark:hover {
  background-image: url("../../images/bookmark-hover.svg");
}

/* .searchResults__newscard-item-bookmark:active {
  background-image: url("../../images/bookmark-filled.svg");
} */

.searchResults__newscard-item-bookmark-clicked {
  background-image: url("../../images/bookmark-filled.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.searchResults__newscard-item-delete {
  background-image: url("../../images/trash.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  padding: 0;

}

.searchResults__newscard-item-delete:hover {
  background-image: url("../../images/trash-active.svg");
}

.searchResults__newscard-item-keyword {
  border-radius: 8px;
  background-color: #ffffff;
  position: absolute;
  top: 24px;
  left: 24px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 8px 22px;
  margin: 0;
  color: #000000;
}

.searchResults__newscard-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 24px;
  padding: 0;
  height: 304px;
}

.searchResults__newscard-item-date {
  margin: 20px 0 0 0;
  padding: 0;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
}

.searchResults__newscard-item-title {
  margin: 12px 0 0 0;
  padding: 0;
  font-family: 'Roboto-Slab', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  line-height: 30px;
  color: #1a1b22;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  /* width: 352px; */
}

.searchResults__newscard-item-text {
  margin: 16px 0 18px 0;
  padding:0;
  height: 110px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #1a1b22;
  overflow: hidden;
  position: relative;
  text-align: justify;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  /* white-space: nowrap ;only single line so no need */
}

.searchResults__newscard-item-source {
  margin: 0 0 24px 0;
  padding: 0;
  font-family: 'Roboto-Slab', 'Arial', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #b6bcbf;
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (max-width: 1260px) {
  .searchResults__newscard{
    max-width: 340px;
    margin: 0;
    padding: 0;
  }
  .searchResults__newscard-item {
    max-width: 320px;
  }
}

@media screen and (max-width: 1009px ) {
  .searchResults__newscard {
    max-width: 360px;
  }
  .searchResults__newscard-item {
    max-width: 360px;
  }
  .searchResults__newscard-item-tooltip {
    width: 125px;
  }
}

/*tablet size */
@media screen and (max-width: 768px) {
  .searchResults__newscard-item {
    max-width: 224px;
    height: 420px;
  }
  .searchResults__newscard-item-image {
    max-width: 224px;
    height: 150px;
  }
  .searchResults__newscard-item-keyword {
    padding: 8px 20px;
    top: 8px;
    left: 8px;
  }
  .searchResults__newscard-item-box {
    top: 8px;
    right: 8px;
  }
  .searchResults__newscard-item-info {
    margin: 0;
    padding: 16px;
  }
  .searchResults__newscard-item-date {
    margin: 0;
  }

  .searchResults__newscard-item-title {
    font-size: 22px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .searchResults__newscard-item-text {
    margin: 10px auto 12px auto;
  }
  .searchResults__newscard-item-source {
    margin: 0;
  }
  .searchResults__newscard-item-tooltip {
    /* display: none; */
    border-radius: 10px;
    border: 0;
    background-color: #ffffff;
    width: 144px;
    height: 40px;
    position: absolute;
    top: 8px;
    right: 69px;
    font-family: 'Roboto', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.06px;
    color: #000000;
  }
}

@media screen and (min-width: 318px )  and (max-width: 767px) {
  .searchResults__newscard-item {
    max-width: 288px;
    height: 440px;
  }
  .searchResults__newscard-item-image {
    max-width: 288px;
    height: 196px;
  }
  .searchResults__newscard-item-tooltip {
    width: 142px;
    position: absolute;
    top: 8px;
    right: 56px;
  }
}
