.notfound {
  max-width: 1440px;
  background-color: #F5F6F7;
  display: flex;
  flex-direction: column;
}

.notfound__image {
  margin-top: 80px;
  background-image: url(../../images/not-found_v1.png);
  width: 83px;
  height: 83px;
  color: black;
  align-self: center;
}

.notfound__title {
  margin-top: 40px;
  font-family: 'Roboto-Slab', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #1A1B22
}

.notfound__text {
  margin-top: 16px;
  margin-bottom: 80px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  width: 356px;
  align-self: center;
  text-align: center;
  inline-size: 250px;
  color: #b6bcbf;
}
