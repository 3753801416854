
.searchResults__newscards-content {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.searchResults__newscards {
  max-width: 1232px;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 16px 16px;
  padding:  0px;
  list-style: none;
}

@media screen and (max-width: 1260px ) {
  .searchResults__newscards {
    grid-template-columns: repeat(3, 320px);
    grid-gap: 12px;
  }
}

@media screen and (max-width: 1009px ) {
  .searchResults__newscards {
    grid-template-columns: repeat(2, 360px);
    grid-gap: 10px;
  }
}

@media screen and (max-width: 768px)  {
  .searchResults__newscards {
    max-width: 768px ;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fit, 224px);
  }
}
@media screen and (max-width: 767px ) {
  .searchResults__newscards {
    max-width: 736px ;
    grid-gap: 8px;
    grid-template-columns: repeat(1 , auto);
  }
}

@media screen and (max-width: 476px ) {
  .searchResults__newscards {
    max-width: 476px ;
    grid-gap: auto;
    grid-template-columns: repeat(1 , 280px);
  }
}

@media screen and (max-width: 320px ) {
  .searchResults__newscards {
    max-width: 320px ;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, 288px);
  }
}
